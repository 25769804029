import Rule from '@mui/icons-material/Rule';

import AdsClick from '@mui/icons-material/AdsClick';

import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import Badge from '@mui/icons-material/Badge';

import BabyChangingStation from '@mui/icons-material/BabyChangingStation';
import ChildCareIcon from '@mui/icons-material/ChildCare';

import Article from '@mui/icons-material/Article';

import React, { useState } from 'react';
import DynamicFeed from '@mui/icons-material/DynamicFeed';

import CreditCard from '@mui/icons-material/CreditCard';

import Restore from '@mui/icons-material/Restore';

import Update from '@mui/icons-material/Update';

import PriorityHigh from '@mui/icons-material/PriorityHigh';

import ConfirmationNumber from '@mui/icons-material/ConfirmationNumber';

import Cake from '@mui/icons-material/Cake';

import Category from '@mui/icons-material/Category';

import FamilyRestroom from '@mui/icons-material/FamilyRestroom';

import LocalShipping from '@mui/icons-material/LocalShipping';

import Tag from '@mui/icons-material/Tag';

import Cart from '@mui/icons-material/ShoppingCart';
import Event from '@mui/icons-material/Event';
import Dashboard from '@mui/icons-material/Dashboard';
import Notifications from '@mui/icons-material/Notifications';
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin';
import { Box } from '@mui/system';
import { EUserType } from '../enums';
import SubMenu from './SubMenu';

interface MenuItem {
	name: string;
	text: string;
	icon: any;
	to?: () => string;
	items?: MenuItem[];
	isOpen?: boolean;
}

const adminMenuItems: MenuItem[] = [
	{ name: 'parents', text: 'PARENTS', icon: <FamilyRestroom/> },
	{
		name: 'events', text: 'EVENTS', icon: <Event/>, items: [
			{ name: 'unhandled', text: 'UNHANDLED_EVENTS', icon: <PriorityHigh/> },
			{ name: 'future', text: 'FUTURE_EVENTS', icon: <Update/> },
			{ name: 'history', text: 'HISTORY_EVENTS', icon: <Restore/> }
		]
	},
	{ name: 'products', text: 'PRODUCTS', icon: <Cart/> },
	{ name: 'product-categories', text: 'PRODUCT_CATEGORIES', icon: <Category/> },
	{ name: 'product-sub-categories', text: 'PRODUCT_SUB_CATEGORIES', icon: <Category/> },
	{ name: 'product-tags', text: 'PRODUCT_TAGS', icon: <Tag/> },
	{ name: 'vendors', text: 'VENDORS', icon: <LocalShipping/> },
	{ name: 'purchases', text: 'PURCHASES', icon: <CreditCard/> },
	{ name: 'service-providers', text: 'SERVICE_PROVIDERS', icon: <Cake/> },
	{ name: 'service-provider-leads', text: 'SERVICE_PROVIDER_LEADS', icon: <ConfirmationNumber/> },
	{ name: 'service-provider-categories', text: 'SERVICE_PROVIDER_CATEGORIES', icon: <Category/> },
	{ name: 'bul-posts', text: 'BUL_POSTS', icon: <DynamicFeed/> },
	{ name: 'invitations', text: 'INVITATIONS', icon: <Article/> },
	{ name: 'children', text: 'CHILDREN', icon: <ChildCareIcon/> },
	{ name: 'kindergartens', text: 'KINDERGARTENS', icon: <BabyChangingStation/> },
	{ name: 'employees', text: 'EMPLOYEES', icon: <Badge/> },
	{ name: 'missing-products', text: 'MISSING_PRODUCTS', icon: <Rule/> },
	{ name: 'ads', text: 'ADS', icon: <BorderOuterIcon/> },
	{ name: 'ad-user-clicks', text: 'AD_USER_CLICKS', icon: <AdsClick/> },
	{ name: 'notifications', text: 'NOTIFICATIONS', icon: <Notifications/>, to: () => `notifications/create` },
];

const employeeMenuItems: MenuItem[] = [
	{ name: 'products', text: 'PRODUCTS', icon: <Cart/> },
	{ name: 'product-categories', text: 'PRODUCT_CATEGORIES', icon: <Category/> },
	{ name: 'product-sub-categories', text: 'PRODUCT_SUB_CATEGORIES', icon: <Category/> },
	{ name: 'product-tags', text: 'PRODUCT_TAGS', icon: <Tag/> },
	{ name: 'parents', text: 'PARENTS', icon: <FamilyRestroom/> },
	{ name: 'children', text: 'CHILDREN', icon: <ChildCareIcon/> },
	{ name: 'kindergartens', text: 'KINDERGARTENS', icon: <BabyChangingStation/> },
	{ name: 'missing-products', text: 'MISSING_PRODUCTS', icon: <Rule/> },
];

const vendorMenuItems: MenuItem[] = [
	{ name: 'products', text: 'PRODUCTS', icon: <Cart/> },
	{ name: 'product-categories', text: 'PRODUCT_CATEGORIES', icon: <Category/> },
	{ name: 'product-sub-categories', text: 'PRODUCT_SUB_CATEGORIES', icon: <Category/> },
	{ name: 'product-tags', text: 'PRODUCT_TAGS', icon: <Tag/> }
];

const useStyles = {
	menuContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '240px',
		backgroundImage: 'linear-gradient(135deg, #2f3ea0, #ae342d)'
	},
	sideBarLogo: {
		maxHeight: '4rem',
		padding: '1rem',
		marginBottom: '2rem'
	},
	sideBarLogoImage: {
		maxHeight: '4rem'
	},
	menuItem: {
		padding: '0 .75rem !important',
		color: 'white !important',
		'& .MuiListItemIcon-root': {
			color: 'white'
		},
		'&[class*="-active"]': {
			fontWeight: 'bold',
			backgroundColor: '#ffffff66 !important'
		}
	},
	subMenuItem: {
		padding: '1rem 2rem !important'
	},
	logout: {
		marginTop: 'auto'
	}
};

export const CustomMenu = ({ onMenuClick, logout }) => {
	const translator = useTranslate();
	const { permissions } = usePermissions();
	const [state, setState] = useState(false);

	const classes = useStyles;

	const handleToggle = (item: MenuItem) => {
		item.isOpen = !item.isOpen;
		setState(!state);
	};

	return (
		<Box sx={classes.menuContainer}>
			<Box sx={classes.sideBarLogo}>
				<Box
					component="img"
					sx={classes.sideBarLogoImage} alt="Company Logo" src="/assets/logo_white.svg"/>
			</Box>
			{(permissions === EUserType.Admin ?
				<MenuItemLink
					{...[] as any}
					to="/"
					exact="/"
					sx={useStyles.menuItem}
					primaryText={translator('DASHBOARD')}
					leftIcon={<Dashboard/>}
					onClick={onMenuClick}
				/> : <></>)}
			{(permissions === EUserType.Admin ? adminMenuItems : permissions === EUserType.Employee ? employeeMenuItems : vendorMenuItems).map(item =>
				item.items?.length ?
					<SubMenu
						handleToggle={() => handleToggle(item)}
						isOpen={item.isOpen ?? false}
						name={item.text}
						icon={item.icon}
						dense={false}
					>
						{(item.items.map(subItem =>
							<MenuItemLink
								{...[] as any}
								sx={{ ...useStyles.menuItem, ...useStyles.subMenuItem }}
								key={subItem.to ? subItem.to() : subItem.name}
								to={`/${item.to ? item.to() : item.name}/${subItem.to ? subItem.to() : subItem.name}`}
								primaryText={translator(subItem.text)}
								leftIcon={subItem.icon}
								onClick={onMenuClick}
								dense={true}
							/>
						))}
					</SubMenu>
					: <MenuItemLink
						{...[] as any}
						sx={useStyles.menuItem}
						key={item.to ? item.to() : item.name}
						to={`/${item.to ? item.to() : item.name}`}
						primaryText={translator(item.text)}
						leftIcon={item.icon}
						onClick={onMenuClick}
						dense={true}
					/>
			)}
			<Box sx={classes.logout}>
				{() => logout}
			</Box>
		</Box>
	);
};
