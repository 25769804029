import * as React from 'react';
import { FC, HtmlHTMLAttributes, memo } from 'react';
import { get } from 'lodash';
import { Link, Typography } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';
import { Phone } from '../interfaces/phone.interface';

export const PhoneField: FC<PhoneFieldProps> = memo<PhoneFieldProps>(
	({ className, emptyText, source, ...rest }: any) => {
		const record = useRecordContext();

		const value: Phone = get(record, source);

		if (value == null) {
			if (emptyText)
				return (
					<Typography
						component="span"
						variant="body2"
						sx={className}
					>
						{emptyText}
					</Typography>
				);

			return <></>;
		}

		if (value?.phone)
			return (
				<Link
					onClick={e => e.stopPropagation()}
					sx={className}
					href={`tel:+${value.prefix}${value.phone}`}
				>
					{`+(${value.prefix})-${value.phone}`}
				</Link>
			);
		else if (value && typeof value === 'string') {
			return (
				<Link
					onClick={e => e.stopPropagation()}
					sx={className}
					href={`tel:${value}`}
				>
					{`${value}`}
				</Link>
			);
		} else {
			return <></>;
		}
	}
);

PhoneField.displayName = 'PhoneField';

export interface PhoneFieldProps
	extends FieldProps,
		HtmlHTMLAttributes<HTMLAnchorElement> {
}
