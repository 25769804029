import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, } from 'react-admin';
import { AdUserClickList } from './AdUserClickList';
import { AdUserClickDatagrid } from './AdUserClickDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { AdUserClick } from './AdUserClick';

export * from './AdUserClick';
export * from './AdUserClickCreate';
export * from './AdUserClickDatagrid';
export * from './AdUserClickEdit';
export * from './AdUserClickList';
export * from './AdUserClickShow';

const AdUserClicksBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode='pessimistic'/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'adId', header: 'מודעה' },
	{ key: 'id', header: '#' },
	{ key: 'userId', header: 'משתמש' },
];

export const AdUserClicksData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: AdUserClick) => entity;

	const adUserClicksFilter = [
		<DateInput source='from__createdAt' label='FROM_CREATED_AT' alwaysOn/>,
		<DateInput source='to__createdAt' label='TO_CREATED_AT' alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('שימושים במודעות', exportFields, exportDataMapper)}
				{...props}
				filters={adUserClicksFilter}
				bulkActionButtons={<AdUserClicksBulkActionButtons/>}
				hasCreate={false}
				title='AD_USER_CLICKS'
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <AdUserClickList/> : <AdUserClickDatagrid/>}
			</List>
		</Fragment>
	);
};
