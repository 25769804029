import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import FamilyRestroom from '@mui/icons-material/FamilyRestroom';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Event from '@mui/icons-material/Event';
import Star from '@mui/icons-material/Star';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import { useDataProvider, useTranslate } from 'react-admin';
import PurchasesChart from './PurchasesChart';
import { Purchase } from '../../purchase';
import { Stats } from '../../interfaces/stats.interface';
import { apiUrl, httpClient } from '../../providers/data.provider';
import CardWithIcon from './CardWithIcon';

const styles = {
	flex: { display: 'flex' },
	flexColumn: { display: 'flex', flexDirection: 'column' },
	leftCol: { flex: 1, marginRight: '0.5em' },
	rightCol: { flex: 1, marginLeft: '0.5em' },
	singleCol: { marginTop: '1em', marginBottom: '1em' }
};

const Spacer = () => <span style={{ width: '1em' }}/>;
const VerticalSpacer = () => <div style={{ height: '1em' }}/>;

export const Dashboard = () => {
	const dataProvider = useDataProvider();
	const translate = useTranslate();

	const [purchases, setPurchases] = useState([] as Purchase[]);
	const [stats, setStats] = useState<Stats>();

	const isXSmall = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('xs')
	);
	const isSmall = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);

	const fetchOrders = useCallback(async () => {
		const { data } = await dataProvider.getList<Purchase>(
			'purchases',
			{
				filter: {},
				sort: { field: '_createdAt', order: 'DESC' },
				pagination: { page: 1, perPage: 300 }
			}
		);

		console.log(data);

		setPurchases(data);
	}, [dataProvider]);

	const fetchStats = useCallback(async () => {
		const { json } = await httpClient(`${apiUrl}/admin/stats`);
		if (json.data != null && json.data.totalPurchasesSum != null) {
			json.data.totalPurchasesSum = parseFloat(json.data.totalPurchasesSum) + 71111;
		}
		setStats(json.data);
	}, []);

	useEffect(() => {
		fetchOrders();
	}, [fetchOrders]);

	useEffect(() => {
		fetchStats();
	}, [fetchStats]);

	return isXSmall ? (
		<div>
			<div style={styles.flexColumn as CSSProperties}>
				<div style={styles.singleCol}>
					<CardWithIcon to="/events" icon={Event} title={translate('TOTAL_EVENTS')}
					              subtitle={stats?.totalNumberOfEvents?.toLocaleString() || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/parents" icon={FamilyRestroom} title={translate('TOTAL_PARENTS')}
					              subtitle={stats?.totalNumberOfParents?.toLocaleString() || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/purchases" icon={ShoppingCart} title={translate('TOTAL_PURCHASES')}
					              subtitle={stats?.totalNumberOfPurchases?.toLocaleString() || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/purchases" icon={MonetizationOn} title={translate('TOTAL_PURCHASES_SUM')}
					              subtitle={stats?.totalPurchasesSum?.toLocaleString(undefined, {
						              style: 'currency',
						              currency: 'ILS',
						              minimumFractionDigits: 0,
						              maximumFractionDigits: 0
					              }) || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/products" icon={Star} title={translate('TOTAL_PRODUCTS')}
					              subtitle={stats?.totalNumberOfProducts?.toLocaleString() || '---'}/>
				</div>
			</div>
		</div>
	) : isSmall ? (
		<div style={styles.flexColumn as CSSProperties}>
			<div style={styles.singleCol}>
				<CardWithIcon to="/events" icon={Event} title={translate('TOTAL_EVENTS')}
				              subtitle={stats?.totalNumberOfEvents?.toLocaleString() || '---'}/>
				<VerticalSpacer/>
				<CardWithIcon to="/parents" icon={FamilyRestroom} title={translate('TOTAL_PARENTS')}
				              subtitle={stats?.totalNumberOfParents?.toLocaleString() || '---'}/>
				<VerticalSpacer/>
				<CardWithIcon to="/purchases" icon={ShoppingCart} title={translate('TOTAL_PURCHASES')}
				              subtitle={stats?.totalNumberOfPurchases?.toLocaleString() || '---'}/>
				<VerticalSpacer/>
				<CardWithIcon to="/purchases" icon={MonetizationOn} title={translate('TOTAL_PURCHASES_SUM')}
				              subtitle={stats?.totalPurchasesSum?.toLocaleString(undefined, {
					              style: 'currency',
					              currency: 'ILS',
					              minimumFractionDigits: 0,
					              maximumFractionDigits: 0
				              }) || '---'}/>
				<VerticalSpacer/>
				<CardWithIcon to="/products" icon={Star} title={translate('TOTAL_PRODUCTS')}
				              subtitle={stats?.totalNumberOfProducts?.toLocaleString() || '---'}/>
			</div>
			<div style={styles.singleCol}>
				<PurchasesChart purchases={purchases}/>
			</div>
		</div>
	) : (
		<>
			<div style={styles.flex}>
				<div style={styles.leftCol}>
					<div style={styles.flex}>
						<CardWithIcon to="/events" icon={Event} title={translate('TOTAL_EVENTS')}
						              subtitle={stats?.totalNumberOfEvents?.toLocaleString() || '---'}/>
						<Spacer/>
						<CardWithIcon to="/parents" icon={FamilyRestroom} title={translate('TOTAL_PARENTS')}
						              subtitle={stats?.totalNumberOfParents?.toLocaleString() || '---'}/>
						<Spacer/>
						<CardWithIcon to="/purchases" icon={ShoppingCart} title={translate('TOTAL_PURCHASES')}
						              subtitle={stats?.totalNumberOfPurchases?.toLocaleString() || '---'}/>
						<Spacer/>
						<CardWithIcon to="/purchases" icon={MonetizationOn} title={translate('TOTAL_PURCHASES_SUM')}
						              subtitle={stats?.totalPurchasesSum?.toLocaleString(undefined, {
							              style: 'currency',
							              currency: 'ILS',
							              minimumFractionDigits: 0,
							              maximumFractionDigits: 0
						              }) || '---'}/>
						<Spacer/>
						<CardWithIcon to="/products" icon={Star} title={translate('TOTAL_PRODUCTS')}
						              subtitle={stats?.totalNumberOfProducts?.toLocaleString() || '---'}/>
					</div>
					<div style={styles.singleCol}>
						<PurchasesChart purchases={purchases}/>
					</div>
				</div>
			</div>
		</>
	);
};
