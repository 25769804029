import { AuthProvider } from 'react-admin';
import { apiUrl, httpClient } from './data.provider';
import { User } from '../interfaces/user.interface';
import { EUserType } from '../enums';

const LOCAL_STORAGE_TOKEN = 'jwt-token';

export const jwtAuthProvider: AuthProvider = {
	login: async ({ username, password }) => {
		const { json } = await httpClient(`${apiUrl}/admin/auth/login`, {
			method: 'POST',
			body: JSON.stringify({ email: username, password })
		});

		localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(json.data));
	},
	checkError: (error) => {
		const status = error.status;
		if (status === 401 || status === 403) {
			return Promise.reject({ message: false });
		}
		return Promise.resolve();
	},
	checkAuth: () => {
		const storedUserData = localStorage.getItem(LOCAL_STORAGE_TOKEN);

		if (storedUserData) {
			const userData = JSON.parse(storedUserData);

			if ([EUserType.Admin, EUserType.Vendor].includes(userData.user?.type)) {
				return Promise.resolve();
			}
		}

		return Promise.reject();
	},
	logout: () => {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN);
		return Promise.resolve();
	},
	getIdentity: async () => {
		const { json } = await httpClient(`${apiUrl}/admin/auth/me`, { method: 'GET' });

		return {
			id: json.data.user?.id,
			fullName: json.data.user?.fullName || 'שם לא ידוע',
			avatar: json.data.user?.profileImageUrl,
			vendor: json.data.vendor
		};
	},
	getPermissions: () => {
		const storedUserData = localStorage.getItem(LOCAL_STORAGE_TOKEN);

		if (storedUserData) {
			const userData = JSON.parse(storedUserData);

			if (userData.user) {
				return Promise.resolve((userData.user as User).type);
			}
		}

		return Promise.reject();
	}
};
